import $ from "jquery"
import "slick-carousel"


$(document).ready(function () {
  function fetchData() {

    fetch("https://redlineukraine.net/api/reports/get.php")
      .then(res => res.json())
      .then((data) => {
        var container = $('<div  id="reports-slider" class="reports-slider" />');
        for (let report of data.reverse()) {
          var wrapper = $('<div class="reports-slider-container"/>')
          wrapper.append(`<div class="reports-slider__img"><img src="http://redlineukraine.net${report.path}" alt="report 1"></div>`)
          wrapper.append(`<div class="reports-slider__description">${report.text}</div>`)
          container.append(wrapper);
        }
        $('.reports-slider').replaceWith(container);
        reportsPointLine.style.width = `${100 / data.length}%`
      }).then(() => {
        $('#reports-slider').slick({
          arrow: false,
          centerMode: true,
          centerPadding: "0",
          slidesToShow: 1,
          infinite: true,
          speed: 300,
          slidesToScroll: 1,
          variableWidth: true,
          initialSlide: 0,
          touchMove: false,
          arrows: false,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                touchMove: true
              }
            },
          ]
        })

        $('#prev-btn').on("click", function () {
          $('#reports-slider').slick("slickPrev")
        });

        $('#next-btn').on("click", function () {
          $('#reports-slider').slick("slickNext")
        });

        $("#reports-slider").on("afterChange", function (event, { slideCount: count }, currentSlide) {
          reportsPointLine.style.left = `${reportsPointLine.clientWidth * currentSlide}px`
        })
      })

    fetch("https://redlineukraine.net/api/team/get.php")
      .then(res => res.json())
      .then((data) => {
        var container = $('<div class="about-people" />');
        for (let teamMember of data) {
          var wrapper = $('<div class="about-person"/>')
          wrapper.append(`<div class="person-photo"><img src="http://redlineukraine.net${teamMember.path}" alt="${teamMember.name}"></div>`)
          wrapper.append(`<div class="person-name">${teamMember.name}</div>`)
          wrapper.append(`<div class="person-position">${teamMember.position}</div>`)
          container.append(wrapper);
        }
        $('.about-people').replaceWith(container);
      })


  }

  fetchData()


  const requirementsPointLine = document.getElementById("point-line")
  const reportsPointLine = document.getElementById("reports-point-line")


  $('.requirements-slider').slick({
    infinite: true,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    centerPadding: 0,
    initialSlide: 0,
    touchMove: false,
    arrows: false,
  })

  $('#prev').on("click", function () {
    $('#requirements-slider').slick("slickPrev")
  });

  $('#next').on("click", function () {
    $('#requirements-slider').slick("slickNext")
  });

  $("#requirements-slider").on("afterChange", function (event, { slideCount: count }, currentSlide) {
    requirementsPointLine.style.left = `${requirementsPointLine.clientWidth * currentSlide}px`
  })



  $('.header-hamburger').on("click", function () {
    $(this).toggleClass('open');
    $("#mobile-header").toggleClass("open")
    $("body, html").toggleClass("overflow-hidden")
  });

  $('.mobile-header-menu__item').on("click", function () {
    $(".header-hamburger").removeClass('open');
    $("#mobile-header").removeClass("open")
    $("body, html").removeClass("overflow-hidden")
  })


})



